
.container {
	padding-top: 20px;
	padding-bottom: 50px;

	font-size: 15px;
	line-height: 25px;

	h3 {
		font-size: 20px !important;
	}
}
